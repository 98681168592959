import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ToTitlecasePipe } from '../core/pipes/toTitlecase.pipe';
import { StripeCurrencyPipe } from '../core/pipes/stripeCurrency.pipe';
import { RemoveUnderscorePipe } from '../core/pipes/removeunderscore.pipe';
import { StElementsComponent } from './st-elements/st-elements.component';
import { TranslateModule } from '@ngx-translate/core';
import { TabSwitcherComponent } from './tab-switcher/tab-switcher.component';
import { PCIInsuranceFraudProvisionsComponent } from './pci-insurance-fraud-provisions/pci-insurance-fraud-provisions.component';
import { GivebackPageComponent } from './giveback-page/giveback-page.component';
import { PrivacyPolicyPageComponent } from './privacy-policy-page/privacy-policy-page.component';
import { TermsOfUsePageComponent } from './terms-of-use-page/terms-of-use-page.component';
import { AboutUsPageComponent } from './about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './contact-us-page/contact-us-page.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NotFoundComponent } from './not-found/not-found.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormErrorMsgPipe } from '../core/pipes/form-error-msg.pipe';
import { TotalPremiumComponent } from './total-premium/total-premium.component';
import { RequiredStarDirective } from '../core/directives/required-star.directive';
import { HeaderBannerComponent } from './header-banner/header-banner.component';
import { FormDescriptorComponent } from './form-descriptor/form-descriptor.component';
import { NewFaqPageComponent } from './new-faq-page/new-faq-page.component';
import { FaqQuestionComponent } from './new-faq-page/faq-question/faq-question.component';
import { PaymentMsiComponent } from './payment-msi/payment-msi.component';
import { DisclosuresPageComponent } from './disclosures-page/disclosures-page.component';
import { InvalidControlScrollDirective } from '../core/directives/invalid-control-scroll.directive';
import { NgxScrollToFirstInvalidModule } from '@ismaestro/ngx-scroll-to-first-invalid';
import { SocialFacebookComponent } from './social/social-facebook/social-facebook.component';
import { SocialComponent } from './social/social.component';
import { SocialInstagramComponent } from './social/social-instagram/social-instagram.component';
import { SocialPinterestComponent } from './social/social-pinterest/social-pinterest.component';
import { SocialTwitterComponent } from './social/social-twitter/social-twitter.component';
import { SocialLinkedinComponent } from './social/social-linkedin/social-linkedin.component';
import { NotSupportedComponent } from './not-supported/not-supported.component';
import { FreewayLayoutFooterSocialComponent } from '../brandings/freeway/freeway-layout/freeway-layout-footer-social/freeway-layout-footer-social.component';
import { FreewayLayoutFooterSocialFacebookComponent } from '../brandings/freeway/freeway-layout/freeway-layout-footer-social/freeway-layout-footer-social-facebook/freeway-layout-footer-social-facebook.component';
import { FreewayLayoutFooterSocialInstagramComponent } from '../brandings/freeway/freeway-layout/freeway-layout-footer-social/freeway-layout-footer-social-instagram/freeway-layout-footer-social-instagram.component';
import { FreewayLayoutFooterSocialTwitterComponent } from '../brandings/freeway/freeway-layout/freeway-layout-footer-social/freeway-layout-footer-social-twitter/freeway-layout-footer-social-twitter.component';
import { FreewayLayoutFooterSocialPinterestComponent } from '../brandings/freeway/freeway-layout/freeway-layout-footer-social/freeway-layout-footer-social-pinterest/freeway-layout-footer-social-pinterest.component';
import { FreewayLayoutFooterSocialLinkedinComponent } from '../brandings/freeway/freeway-layout/freeway-layout-footer-social/freeway-layout-footer-social-linkedin/freeway-layout-footer-social-linkedin.component';
import { SwitchLanguagesComponent } from './layout/header/switch-languages/switch-languages.component';
import { LocalizedDatePipe } from '../core/pipes/localized-date.pipe';
import { TotalPremiumStatesComponent } from './total-premium-states/total-premium-states.component';
import { SuccessPolicyComponent } from './success-policy/success-policy.component';
import { DisclosuresQbeComponent } from './disclosures-qbe/disclosures-qbe.component';
import { QuestionsPopupComponent } from './questions-popup/questions-popup.component';
import { StElementsQbeComponent } from './st-elements-qbe/st-elements-qbe.component';
import { InsuranceProducerLicensureComponent } from './insurance-producer-licensure/insurance-producer-licensure.component';
import { FileMbPipe } from '../core/pipes/filemb.pipe';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { IntCurrency } from '../core/pipes/intCurrency';
import { SharedMaterialModule } from './shared-material.module';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { FreewayLayoutFooterComponent } from '../brandings/freeway/freeway-layout/freeway-layout-footer/freeway-layout-footer.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { MainImageRightComponent } from './main-image-right/main-image-right.component';
import { MainImageLeftComponent } from './main-image-left/main-image-left.component';
import { HeaderMenuComponent } from './layout/header/header-menu/header-menu.component';
import { HeaderLoginLogoutComponent } from './layout/header/login-logout/login-logout.component';
import { HeaderLogoSectionComponent } from './layout/header/logo-section/logo-section.component';
import { MatMenuModule } from '@angular/material/menu';
import { TermsPrivacyModalComponent } from './terms-privacy-modal';
import { InfiniteScrollDirective } from './infinite-scroll';
import { PoweredByGcComponent } from './powered-by-gc/powered-by-gc.component';
import { BrandingAttrDirective } from './directives/branding-attr.directive';
import { DisplayBrandingAttrPipe } from './pipes/display-branding-attr.pipe';
import { UnlessBrandingAttrDirective } from './directives/unless-branding-attr.directive';
import { EstimatedPremiumComponent } from './estimated-premium/estimated-premium.component';

const components = [
  HeaderComponent,
  HeaderMenuComponent,
  HeaderLoginLogoutComponent,
  HeaderLogoSectionComponent,
  FooterComponent,
  StElementsComponent,
  TabSwitcherComponent,
  PCIInsuranceFraudProvisionsComponent,
  GivebackPageComponent,
  PrivacyPolicyPageComponent,
  TermsOfUsePageComponent,
  AboutUsPageComponent,
  ContactUsPageComponent,
  NotFoundComponent,
  TotalPremiumComponent,
  HeaderBannerComponent,
  NewFaqPageComponent,
  FaqQuestionComponent,
  FormDescriptorComponent,
  PaymentMsiComponent,
  DisclosuresPageComponent,
  SocialFacebookComponent,
  SocialComponent,
  SocialInstagramComponent,
  SocialPinterestComponent,
  SocialTwitterComponent,
  SocialLinkedinComponent,
  FreewayLayoutFooterComponent,
  FreewayLayoutFooterSocialComponent,
  FreewayLayoutFooterSocialFacebookComponent,
  FreewayLayoutFooterSocialInstagramComponent,
  FreewayLayoutFooterSocialTwitterComponent,
  FreewayLayoutFooterSocialPinterestComponent,
  FreewayLayoutFooterSocialLinkedinComponent,
  NotSupportedComponent,
  SwitchLanguagesComponent,
  TotalPremiumStatesComponent,
  SuccessPolicyComponent,
  InsuranceProducerLicensureComponent,
  DisclosuresQbeComponent,
  QuestionsPopupComponent,
  StElementsQbeComponent,
  FileUploadComponent,
  MainImageRightComponent,
  MainImageLeftComponent,
  TermsPrivacyModalComponent,
  PoweredByGcComponent,
  EstimatedPremiumComponent
];

const pipes = [
  IntCurrency,
  FileMbPipe,
  LocalizedDatePipe,
  FormErrorMsgPipe,
  ToTitlecasePipe,
  StripeCurrencyPipe,
  RemoveUnderscorePipe,
  DisplayBrandingAttrPipe
];

const directive = [
  InvalidControlScrollDirective,
  RequiredStarDirective,
  InfiniteScrollDirective,
  BrandingAttrDirective,
  UnlessBrandingAttrDirective
];

const modules = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
  FlexLayoutModule,
  NgxScrollToFirstInvalidModule,
  SharedMaterialModule,
  ClickOutsideModule,
  NgxMaskDirective,
  NgxMaskPipe,
  MatMenuModule
];

@NgModule({
  declarations: [
    ...components,
    ...pipes,
    ...directive
  ],
  imports: [
    ...modules
  ],
  exports: [
    ...modules,
    ...components,
    ...pipes,
    ...directive
  ],
  providers: [
    provideNgxMask()
  ]
})
export class SharedModule { }
